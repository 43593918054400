import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
import { createPinia } from 'pinia'
const pinia = createPinia()
import router from './router'
import "@/utils/rem.js";
import flexible from "amfe-flexible";

app.use(router)
app.use(flexible)
app.use(pinia)
app.mount('#app')
