import { createRouter, createWebHashHistory } from 'vue-router'
import app from '../App.vue'

const routes = [
  {
    path: '/',
    name: 'app',
    component: app,
    meta: {title: "头铁的皮皮星"}
  },
  // {
  //   path: '/phone',
  //   name: 'phone',
  //   component: () => import('../views/phone/index.vue'),
  //   meta: {title: "头铁的皮皮星"}
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router
