<template>
<div class="Index-phone">
    <div class="scrollBox">
        <serchBox />
        <slideShow />
        <hotEntry />
        <classModule />
        <homeList />
    </div>
</div>
</template>

<script>
import serchBox from "../phone/components/search-box.vue"
import slideShow from "../phone/components/slide-show.vue"
import hotEntry from "../phone/components/hot-entry.vue"
import classModule from "../phone/components/class-module.vue"
import homeList from "../phone/components/home-list.vue"
export default{
    components: {serchBox, slideShow, hotEntry, classModule, homeList},
}
</script>

<style scoped>
.Index-phone{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #000;
    overflow: hidden;
}
.scrollBox{
    position: relative;
    flex: 1;
    width: 100%;
    margin: 0 auto 0;
    overflow-x: hidden;
    overflow-y: scroll;
}
</style>
