<template>
<div class="classModule">
    <div class="s-name">分类信息</div>
    <Swiper
        class="s-content"
        :slidesPerView="2.5"
    >
        <SwiperSlide
            v-for="(item, index) in pageData"
            :key="index"
        >
            <div class="cBox" :style="{backgroundImage: 'url('+item.imgSrc+')'}">
                <div class="durBox" v-if="item.durImg">
                    <img :src="item.durImg" alt="" srcset="">
                </div>
            </div>
        </SwiperSlide>
    </Swiper>
</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
    components: {Swiper, SwiperSlide},
    data() {
        return {
            pageData: [{
                imgSrc: require("../assets/phone/class-module-img1.png"),
                durImg: require("../assets/phone/class-module-dur1.png")
            },{
                imgSrc: require("../assets/phone/class-module-img2.png"),
                durImg: require("../assets/phone/class-module-dur2.png")
            },{
                imgSrc: require("../assets/phone/class-module-img3.png"),
                durImg: require("../assets/phone/class-module-dur3.png")
            }]
        }
    },
};
</script>

<style scoped>
.classModule{
    width: 100%;
    height: 280px;
}
.s-name{
    width: 702px;
    height: 74px;
    line-height: 74px;
    font-size: 35px;
    font-weight: 700;
    color: rgba(255, 255, 255, .8);
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 24px;
    margin-bottom: 26px;
}
.s-content{
    width: 702px;
    height: 160px;
    padding: 0 24px;
    overflow: visible;
}
.cBox{
    position: relative;
    width: 246px;
    height: 160px;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.cBox .durBox{
    display: flex;
    position: absolute;
    right: -18px;
    bottom: 14px;
    z-index: 2;
    width: 180px;
    height: 180px;
}
</style>
