<template>
<div class="homeList">
    <Swiper
        class="tab-cat"
        :slidesPerView="7"
    >
        <SwiperSlide
            class="tItem"
            :class="tabNum == index ? 'tActive' : ''"
            v-for="(item, index) in pageData"
            :key="index"
            :style="[tabNum == index ? {backgroundImage: 'url('+tabBgSrc+')'} : '']"
        >
            {{item.name}}
        </SwiperSlide>
    </Swiper>
</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
    components: {Swiper, SwiperSlide},
    data() {
        return {
            tabNum: 0,
            tabBgSrc: require("../assets/phone/home-list-tab-bg.png"),
            pageData: [{
                name: "推荐",
            },{
                name: "其他",
            }],
        }
    },
    methods:{
        
    }
};
</script>

<style scoped>
.homeList{
    width: 100%;
    height: auto;
    overflow: hidden;
}
.tab-cat{
    width: 100%;
    height: 80px;
    margin: 0 auto 14px;
}
.tItem{
    height: 80px;
    line-height: 70px;
    font-size: 28px;
    font-weight: 400;
    color: rgba(255, 255, 255, .8);
    text-align: center;
    background-position: center center;
    background-size: auto 100%;
    background-repeat: no-repeat;
}
.tActive{
    line-height: 65px;
    font-size: 35px;
    font-weight: 700;
}
</style>