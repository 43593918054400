<template>
<div class="serchBox">
    <div class="s-content">
        <div class="s-serch">
            <div class="s-input">
                <input type="text" v-model="serchTxt" @focus="Onfocus()" @blur="onblur()">
            </div>
            <div class="search-icon" v-if="searchIconSrc"><img :src="searchIconSrc" alt="" srcset=""></div>
            <div
                class="scan-icon"
                v-if="scanIconSrc || delTxtIconSrc"
                @click="scanIconIsShow ? scanTo() : clearSerchTxt()"
            >
                <img v-if="scanIconSrc && delTxtIconSrc" :src="[scanIconIsShow ? scanIconSrc : delTxtIconSrc]" alt="" srcset="">
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            searchIconSrc: require("@/assets/search-icon.png"),
            scanIconSrc: require("@/assets/scan-icon.png"),
            delTxtIconSrc: require("@/assets/del-txt-icon.png"),
            scanIconIsShow: true,
            serchTxt: "输入关键词搜索",
        }
    },
    methods: {
        clearSerchTxt(){    // 清除输入框文字
            this.serchTxt = ""
            this.onblur()
        },
        scanTo(){   // 扫码

        },
        Onfocus(){        
            if (this.serchTxt.replace(/\s/g, "") == "输入关键词搜索") {
                this.serchTxt = ""
                this.scanIconIsShow = false
            }
        },
        onblur(){
            if (this.serchTxt.replace(/\s/g, "") == "") {
                this.serchTxt = "输入关键词搜索"
                this.scanIconIsShow = true
            }
        },
    },
};
</script>

<style scoped>
.serchBox{
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: auto;
    overflow: hidden;
}
.s-content{
    display: flex;
    justify-content: center;
    align-content: center;
    width: 702px;
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    text-align: left;
    padding: 30px 0;
}
.s-serch{
    position: relative;
    flex: 1;
    background-color: #262626;
    border-radius: 80px;
}
.s-serch .s-input{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}
.s-serch .s-input input{
    display: block;
    width: 100%;
    height: 100%;
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    font-weight: 700;
    color: rgba(255, 255, 255, .2);
    text-align: left;
    background: none;
    border: none;
    padding: 0 100px;
    box-sizing: border-box;
}
.s-serch .s-durc{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.s-serch .search-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100px;
    height: 100%;
}
.s-serch .search-icon img{
    width: 80px;
    height: 80px;
}
.s-serch .scan-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 11;
    width: 100px;
    height: 100%;
}
.s-serch .scan-icon img{
    width: 80px;
    height: 80px;
}
</style>
