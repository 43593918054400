<template>
  <transition name="fade" mode="out-in" v-if="isPhone">
    <phone />
  </transition>
  <transition name="fade" mode="out-in" v-else>
    <home />
  </transition>
</template>

<script>
import phone from "../src/views/phone/index.vue"
import home from "../src/views/home/index.vue"
export default{
  components: {phone, home},
  data() {
    return {
      isPhone:true,
      terminal:null,
    }
  },
  methods: {
    detectDevice(){ // 判断当前设备是PC端、移动端还是平板端
      const userAgent = navigator.userAgent;
      if (/Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {  // 移动端
        this.terminal = "phone"
      }
      else if (/iPad/i.test(userAgent)) { // 平板端
        this.terminal = "iPad"
      }
      else{  // PC端
        this.terminal = "pc"
      }
    },
    onResize(){
      var base = null
      let winW = window.innerWidth;
      let winH = window.innerHeight;
      if (winW < winH && (winW / winH) < 0.8) {
        base = true;
      } else {
        base = false;
      }
      this.isPhone = base
    }
  },
  created(){
    // this.onResize()
    // window.addEventListener("resize", this.onResize); // 根据视口动态改变
  },
}
</script>

<style>
body {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  margin: 0;
  overflow: hidden;
}
input {
  outline: none;
}
:focus{
  outline: none;
}
*{
  -webkit-tap-highlight-color:transparent;
}
select::-ms-expand {
  display: none;
}
select::-webkit-outer-spin-button,
select::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
img{
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  line-height: 1.5;
  font-size: 30px;
  font-weight: 400;
  background-color: #fff;
  overflow: hidden;
}
.fade-enter-active,
.fade-leave-active{
  transition: opacity .5s;
}
.fade-enter,
.fade-leave-to{
  opacity: 0;
}
</style>
