<template>
<div class="hotEntry" v-if="pageData">
    <div class="s-content">
        <div
            class="s-item"
            v-for="(item, index) in pageData"
            :key="index"
        >
            <div class="iconBox"></div>
            <div class="name">标签标签</div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            pageData: [1,2,3,4]
        }
    },
};
</script>

<style scoped>
.hotEntry{
    width: 100%;
    height: auto;
    overflow: hidden;
}
.s-content{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    overflow: hidden;
}
.s-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 180px;
}
.s-item .iconBox{
    display: flex;
    width: 90px;
    height: 90px;
    background-color: #95c361;
    border-radius: 90px;
    overflow: hidden;
    margin-bottom: 14px;
}
.s-item .name{
    width: 90%;
    height: 35px;
    line-height: 35px;
    font-size: 28px;
    font-weight: 500;
    color: #95c361;
    text-align: center;
    white-space: nowrap;
    margin: 0 auto 0;
}
</style>
