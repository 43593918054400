<template>
<div class="Index-home">
    请手机端查看～嘿嘿嘿嘿嘿嘿
</div>
</template>

<script>
export default{}
</script>

<style scoped>
.Index-home{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    line-height: 1.5;
    font-size: 28px;
    font-weight: 400;
    color: #333;
}
</style>
