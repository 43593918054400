<template>
<div class="slideShow" v-if="pageData">
    <Swiper class="s-content">
        <SwiperSlide
            class="s-item"
            v-for="(item, index) in pageData"
            :key="index"
            @swiper="OnSwiper"
        >
            <div class="s-banner"></div>
        </SwiperSlide>
    </Swiper>
</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
    components: {Swiper, SwiperSlide},
    data() {
        return {
            swiper: null,
            pageData: [1,2]
        }
    },
    methods: {
        OnSwiper(swiper){
            this.swiper = swiper
        },
    },
};
</script>

<style scoped>
.slideShow{
    width: 100%;
    height: 310px;
    overflow: hidden;
    margin-bottom: 24px;
}
.s-content{
    width: 100%;
    height: 100%;
}
.s-item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.s-banner{
    width: 702px;
    height: 100%;
    background-color: #262626;
    border-radius: 30px;
}
</style>
